import React, { useEffect, useState } from 'react'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import styled from 'styled-components'
import Title from './../components/Title'
import { InnerLayout } from '../styles/Layouts'
import GitHubIcon from '@material-ui/icons/GitHub'
import LanguageIcon from '@material-ui/icons/Language'
import Button from './../components/Button'
import Loading from './../components/Loading'
import { API } from '../data/Api'

function FavorisDeveloppeurPage({ theme }) {
  const [swLoaded, setSwLoaded] = useState(false)
  const [button, setButtons] = useState(['Tous'])
  const [favoris, setFavoris] = React.useState([])
  const [favorisNonFiltre, setFavorisNonFiltre] = React.useState([])

  const httpGetFavoris = async() => {
    const url = API + 'bressani.dev/favoris/dev'
    await axios.get(url)
      .then((res) => {
        const data = res.data
        setFavoris(data)
        setFavorisNonFiltre(data)
        setButtons(['Tous', ...new Set(data.map(item => item.dz02_categorie.nom))])
      })
      .catch((err) => {
        console.error(err)
        toast.error(<p>Erreur de chargement des données:<br />{err.message}</p>)
      })
  }

  useEffect(() => {
    httpGetFavoris().then(() => {
      setSwLoaded(true)
    }).catch(() => {
      setSwLoaded(true)
    })
  }, [])

  const filter = (button) => {
    if (button === 'Tous') {
      setFavoris(favorisNonFiltre)
      return
    }
    const filteredData = favorisNonFiltre.filter(item => item.dz02_categorie.nom === button)
    setFavoris(filteredData)
  }

  return (
    !swLoaded ? <Loading /> : <FavorisDeveloppeurPageStyled>
      <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
      <Title title={'Favoris'} span={'Développeur'} />
      <InnerLayout>
        <Button filter={filter} button={button} />
        <div className="menu">
          {
            favoris.map((item) => {
              return <div className="favoris-item" key={item.id}>
                <div className="favoris-content">
                  <div className="favoris-image">
                    <img
                      src={'data:image/png;base64,' + item.image_b64}
                      alt={item.title}
                      title={item.titre}
                    />
                    <ul>
                      {
                        item.lien_repo !== '' ? (
                          <li>
                            <a href={ item.lien_repo } target='_blank'><GitHubIcon /></a>
                          </li>) : ('')
                      }
                      {
                        item.lien_web !== '' ? (
                          <li>
                            <a href={ item.lien_web } target='_blank'><LanguageIcon /></a>
                          </li>) : ('')
                      }
                    </ul>
                  </div>
                  <h6>{ item.titre }</h6>
                  <p>{ item.sous_titre }</p>
                </div>
              </div>
            })
          }
        </div>
      </InnerLayout>
    </FavorisDeveloppeurPageStyled>
  )
}

const FavorisDeveloppeurPageStyled = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
   .menu {
        display:grid;
        grid-template-columns: repeat(3, 1fr);
        grid-gap:3rem;
        @media screen and (max-width:920px){
            grid-template-columns: repeat(2, 1fr);
        }
        @media screen and (max-width:670px){
            grid-template-columns: repeat(1, 1fr);
        }

        .favoris-item {
            .favoris-content{
                display:block;
                position: relative;
                overflow:hidden;
                h6 {
                    font-size:1.4rem;
                    margin:.2rem 0;
                }
                img {
                    width:100%;
                    height:100%;
                    object-fit:cover;
                }
                ul {
                    display:none;
                    transform: translateY(-600px);
                    transition: all .4s ease-in-out;
                    position: absolute;
                    left:50%;
                    top:40%;
                    opacity: 1;
                    li {
                        background: var(--border-color);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        padding: 1rem;
                        border-radius: 50%;
                        width: 3rem;             
                        height:3rem;
                        margin: 0 0.5rem;
                        transition: all .4s ease-in-out;
                        &:hover{
                            background: var(--primary-color);
                        }
                        a{
                            display: flex;
                            align-items: center;
                            justify-content: center;
                            transition: all .4s ease-in-out;
                        }
                    }
                }
                .favoris-image {
                    &::before {
                        content: '';
                        position: absolute;
                        left: 2%;
                        top:4%;
                        height:0;
                        width:0;
                        transition: all .4s ease-in-out;
                    }
                }
                .favoris-image:hover {
                    ul {
                        transform: translateY(0);
                        transform: translate(-50%, -50%);
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        transition: all .4s ease-in-out;
                        li{
                            transition: all .4s ease-in-out;
                            &:hover{
                                background: var(--primary-color);
                            }
                            a{
                                display: flex;
                                align-items: center;
                                justify-content: center;
                                transition: all .4s ease-in-out;
                            }
                        }
                        li:hover {
                            svg {
                                color:var(--font-color);
                            }
                        }
                        svg {
                            font-size:2rem;
                        }
                    }
                    &::before {
                        height:calc(100% - 28%);
                        width:calc(100% - 4%);
                        background-color: white;
                        opacity: 0.9;
                        transform-origin:left;
                        transition: all .4s ease-in-out;
                    }
                }
            }
        }
   }
`

export default FavorisDeveloppeurPage
