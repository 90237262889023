import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import Title from './../components/Title'
import { InnerLayout } from '../styles/Layouts'
import PrimaryButton from './../components/PrimaryButton'
import ContactInfoItem from './../components/ContactInfoItem'
import PhoneIcon from '@material-ui/icons/Phone'
import EmailIcon from '@material-ui/icons/Email'
import LocationOnIcon from '@material-ui/icons/LocationOn'
import Loading from './../components/Loading'
import { API } from '../data/Api'
import axios from 'axios'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ReCAPTCHA from 'react-google-recaptcha'

function ContactPage({ theme }) {
  const [swLoaded, setSwLoaded] = useState(false)
  const phone = <PhoneIcon />
  const email = <EmailIcon />
  const location = <LocationOnIcon />
  const [inputField, setInputField] = useState({
    nom: '',
    sujet: '',
    email: '',
    message: ''
  })
  const recaptcha = React.createRef()

  useEffect(() => {
    setTimeout(function() {
      setSwLoaded(true)
    }, 100)
  }, [])

  const SubmitForm = () => {
    const captchaValue = recaptcha.current.getValue()
    if (!captchaValue) {
      toast.error(<p>Erreur lors de l'envoi du message.<br />Remplissez le reCAPTCHA !</p>)
    } else {
      const body = JSON.stringify({
        email: {
          nom: inputField.nom,
          sujet: inputField.sujet,
          email: inputField.email,
          message: inputField.message
        }
      })

      axios.request({
        method: 'post',
        url: API + 'bressani.dev/mail_contact',
        data: body,
        headers: { 'Content-Type': 'application/json' }
      })
        .then(() => {
          toast(<p>Message envoyé !</p>)
          setInputField({
            nom: '',
            sujet: '',
            email: '',
            message: ''
          })
        }).catch((err) => {
          console.error(err)
          toast.error(<p>Erreur lors de l'envoi du message.<br />Message non transmis.<br />{err.message}</p>)
        })
    }
  }

  const inputsHandler = (e) => {
    const { name, value } = e.target
    setInputField((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  return (
    !swLoaded ? <Loading /> : <ContactPageStyled>
      <ToastContainer theme={theme === 'light-mode' ? 'light' : 'dark'}/>
      <Title title={'Contact'} span={'Moi'} />
      <InnerLayout className="contact-section">
        <div className="contact-info">
          <h4>Informations</h4>
          <ContactInfoItem icon={phone} heading={'Téléphone'} text={'022 349 09 69'} />
          <ContactInfoItem icon={email} heading={'Email'} text={'stephane [at] bressani [point] dev'} />
          <ContactInfoItem icon={location} heading={'Localisation'} text={'Genève'} />
        </div>
        <div className="contact-info">
          <form onSubmit={(e) => { e.preventDefault(); SubmitForm() }} className="form-part">
            <h4>Me contacter</h4>
            <div className="form-group">
              <label htmlFor="nom">Nom</label>
              <input
                type="text"
                name="nom"
                id="nom"
                onChange={inputsHandler}
                value={inputField.nom}
              />
            </div>
            <div className="form-group">
              <label htmlFor="email">Email</label>
              <input
                type="text"
                name="email"
                id="email"
                onChange={inputsHandler}
                value={inputField.email}
              />
            </div>
            <div className="form-group">
              <label htmlFor="subject">Sujet</label>
              <input
                type="text"
                name="sujet"
                id="sujet"
                onChange={inputsHandler}
                value={inputField.sujet}
              />
            </div>
            <div className="form-group">
              <label htmlFor="message">Message</label>
              <textarea
                name="message"
                id="message"
                cols="30"
                rows="5"
                onChange={inputsHandler}
                value={inputField.message}
              ></textarea>
            </div>
            <div className="form-group">
              <PrimaryButton btn={'Envoi message'} />
              <ReCAPTCHA
                ref={recaptcha}
                sitekey={process.env.REACT_APP_SITE_KEY}
              />
            </div>
          </form>
        </div>
      </InnerLayout>
    </ContactPageStyled>
  )
}

const ContactPageStyled = styled.div`
  /* fade */
  -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
  animation: my-fade 1s;
  .contact-section {
    display:grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap:3rem;
    @media screen and (max-width:768px){
      grid-template-columns: repeat(1, 1fr);
    }
    .contact-info {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      width:100%;
      h4{
        padding: 1rem 0;
        font-size:1.8rem;
        @media screen and (max-width:600px) {
            font-size:1.5rem;
        }
      }
    }
    .form-part {
      width:100%;
      h4{
        padding: 1rem 0;
        font-size:1.8rem;
        @media screen and (max-width:600px){
          font-size:1.5rem;
        }
      }
      .form-group {
        margin-top:2rem;
        position: relative;
        width:100%;
        label {
          position: absolute;
          left:20px;
          top: -17px;
          display: inline-block;
          background-color: var(--background-color);
          padding: 0 .5rem;
          color: inherit;
        }
        input {
          border: 1px solid var(--border-color-2);
          outline: none;
          background-color: transparent;
          padding: 10px 15px;
          width:100%;
          color: inherit;
          border-radius: .2rem;
          background-color: transparent;
        }
        textarea {
          resize: none;
          background-color: transparent;
          border: 1px solid var(--border-color-2);
          outline: none;
          color: inherit;
          width: 100%;
          padding: .8rem 1rem;
          border-radius: .2rem;
        }
      }
    }
  }
`

export default ContactPage
