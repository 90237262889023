import React from 'react'
import styled from 'styled-components'
import { RotatingLines } from 'react-loader-spinner'

function Loading({ filter, button }) {
  return (
    <LoadingStyled>
      <div className="loading">
        <RotatingLines
          strokeColor="grey"
          strokeWidth="5"
          animationDuration="0.75"
          width="96"
          visible={true}
        />
      </div>
    </LoadingStyled>
  )
}

const LoadingStyled = styled.button`
  position: relative;
  display: contents;
  .loading {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    /* fade */
    -webkit-animation: my-fade 1s; /* Chrome, Safari, Opera */
    animation: my-fade 1s;
  }
`

export default Loading
