import { createGlobalStyle } from 'styled-components'

const GlobalStyle = createGlobalStyle`
  .dark-mode {
    --primary-color: #ff9800;
    --background-color: #0a373f; // solarized dark
    --sidebar-color: #212120; // terminal
    --font-color: #F1F1F1;
    --paragraph-color: #bbb;
    --border-color: rgb(152, 165, 164); // terminal select
    --border-color-2: #4c4c4c;
  }

  .light-mode {
    --primary-color: #ff9800;
    --background-color: #fcf4e2; // solarized light
    --sidebar-color: #f3ecdc; // selection solarized
    --font-color: #191919;
    --paragraph-color: #767676;
    --border-color: #ccc;
    --border-color-2: #b2b2b2;
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    list-style: none;
    text-decoration: none;
    font-family: 'Poppins', sans-serif;
  }

  body {
    background-color: var(--background-color);
    color: var(--font-color);
  }

  body::-webkit-scrollbar {
    background: #383838;
    width: 9px;
  }

  body::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #6b6b6b;
  }

  body::-webkit-scrollbar-track {
    border-radius: 10px;
    background: #383838;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: 'Mulish', sans-serif;
    font-weight: 900;
  }

  h1 {
    font-size: 3rem;
    @media screen and (max-width: 768px) {
      font-size: 2.4rem;
    }
    @media screen and (max-width: 625px) {
      font-size: 2rem;
    }
    @media screen and (max-width: 300px) {
      font-size: 1.8rem;
    }

    span {
      color: var(--primary-color);
      font-size: 3rem;
      @media screen and (max-width: 768px) {
        font-size: 2.4rem;
      }
      @media screen and (max-width: 625px) {
        font-size: 2rem;
      }
      @media screen and (max-width: 300px) {
        font-size: 1.8rem;
      }
    }
  }

  a {
    font-family: inherit;
    color: inherit;
  }

  button {
    font-family: 'Montserrat', sans-serif;
    outline: none;
    border: none;
  }

  p {
    letter-spacing: .1rem;
    word-spacing: .1rem;
    color: var(--paragraph-color);
    font-size: 1rem;
    @media screen and (max-width: 625px) {
      font-size: .9rem;
    }
  }

  /* Setting Wheel Toggler */
  .setting-wheel {
    position: fixed;
    right: 0;
    top: 40%;
    background: var(--primary-color);
    width: 2rem;
    height: 2rem;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      font-size: 1.5rem;
      color: var(--font-color);
      -webkit-animation: rotating 10s linear infinite;
      -moz-animation: rotating 10s linear infinite;
      -ms-animation: rotating 10s linear infinite;
      -o-animation: rotating 10s linear infinite;
      animation: rotating 10s linear infinite;
      @keyframes rotating {
        from {
          -ms-transform: rotate(0deg);
          -moz-transform: rotate(0deg);
          -webkit-transform: rotate(0deg);
          -o-transform: rotate(0deg);
          transform: rotate(0deg);
        }
        to {
          -ms-transform: rotate(360deg);
          -moz-transform: rotate(360deg);
          -webkit-transform: rotate(360deg);
          -o-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

    }
  }

  /*Floating Toggler*/
  .light-dark-mode {
    position: fixed;
    right: 0;
    top: 46%;
    background: var(--primary-color);
    width: 6.0rem;
    height: 2.5rem;
    z-index: 15;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: all .4s ease-in-out;
    transform: translateX(100%);
    z-index: 20;

    svg {
      display: flex;
      align-items: center;
      font-size: 1.5rem;
    }
  }

  .theme {
    transform: translateX(0%);
    z-index: 20;

  }

  /* Toggler Menu */
  .hamburger-bar {
    position: absolute;
    right: 5%;
    top: 3%;
    z-index: 15;
    display: none;

    svg {
      font-size: 2.1rem;
      color: var(--font-color);
    }
  }

  .Nav-toggle {
    transform: translateX(0);
    z-index: 20;
  }

  @media screen and (max-width: 1200px) {
    .hamburger-bar {
      display: block;
    }
  }

  @media screen and (min-width: 0px) and (max-width: 599px) {
    .image-part {
      height: 430px !important;
      width: 430px !important;
    }
  }

  @media screen and (min-width: 600px) and (max-width: 1023px) {
    .image-part {
      height: 430px !important;
      width: 430px !important;
    }
  }

  @media screen and (min-width: 1024px) and (max-width: 1599px) {
    .image-part {
      height: 500px !important;
      width: 500px !important;
    }
  }

  @media screen and (min-width: 1600px) {
    .image-part {
      height: 600px !important;
      width: 600px !important;
    }
  }

  .image-no-border {
    border: 0 !important;
  }

  @-webkit-keyframes logo-rotate {
    from {
      -webkit-transform: rotate(0deg);
    }
    to {
      -webkit-transform: rotate(360deg);
    }
  }

  .logo-rotate {
    -webkit-animation: rotating 20s linear infinite;
    border: 0 !important;
    width: 130px;
    height: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    z-index: -1;
  }
  
  .logo-bg {
    border: 0 !important;
    width: 260px;
    height: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: -2;
  }
  .logo-bressani-bg {
    border: 0 !important;
    width: 260px;
    height: 130px;
    background-size: cover;
    background-repeat: no-repeat;
    position: absolute;
    z-index: 0;
  }
  .icon-svg {
    width: 22x;
    height: 22px;
  }
  .icon-svg-portfolio {
    width: 28px !important;
    height: 28px !important;
  }
  .loading-parent {
    position: relative;
    display: contents;
  }  
  .loading-child {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -moz-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    -o-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  @-webkit-keyframes my-fade { /* Chrome, Safari, Opera */
    from {opacity: 0}
    to {opacity: 1}
  }
  /* Standard syntax */
  @keyframes my-fade {
    from {opacity: 0}
    to {opacity: 1}
  }
`

export default GlobalStyle
