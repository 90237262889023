import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import logo from '../images/Svg/logo.svg'
import logoBg from '../images/Svg/logobg.svg'
import logoBressaniDevLight from '../images/Svg/logo-bressanidev-light.svg'
import logoBressaniDevDark from '../images/Svg/logo-bressanidev-dark.svg'
/*
            <li>
                <NavLink to="/services"
                         exact
                         activeClassName="active-menu"
                         onClick={()=>setNavToggle(false)}>Services</NavLink>
            </li>
            <li>
                <NavLink to="/resume"
                         exact
                         activeClassName="active-menu"
                         onClick={()=>setNavToggle(false)}>Resume</NavLink>
            </li>
            <li>
                <NavLink to="/blog"
                         exact
                         activeClassName="active-menu"
                         onClick={()=>setNavToggle(false)}>Blog</NavLink>
            </li>
 */
function Navbar({ setNavToggle, theme }) {
  return (
    <NavbarStyled>
      <div className="avatar-logo">
        <div
          className="logo-bressani-bg"

          style={{ backgroundImage: `url(${theme === 'light-mode' ? logoBressaniDevLight : logoBressaniDevDark}` }}
        ></div>
        <div
          className="logo-bg"

          style={{ backgroundImage: `url(${logoBg})` }}
        ></div>
        <div
          className="logo-rotate"
          style={{ backgroundImage: `url(${logo})` }}
        ></div>
      </div>
      <ul className="nav-items">
        <li>
          <NavLink
            to="/"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>
              Accueil
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/about"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>
              A propos
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/portfolio"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>
              Portfolio
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/favoris-dev"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>
              Favoris développeur
          </NavLink>
        </li>
        <li>
          <NavLink
            to="/contact"
            exact
            activeClassName="active-menu"
            onClick={() => setNavToggle(false)}>
              Contact
          </NavLink>
        </li>
      </ul>
      <footer>
        <p>bressani.dev</p>
      </footer>
    </NavbarStyled>
  )
}

const NavbarStyled = styled.header`
  height: 100%;
  width: 100%;
  display:flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  border-right:2px solid var(--border-color);

  .avatar-logo{
    width:100%;
    height: 25%;
    /*
    border-bottom: 1px solid var(--border-color);
    */
    text-align:center;
    padding: 1rem 0;
    display:flex;
    align-items:center;
    justify-content:center;
    img{
      height:90%;
      border-radius: 50%;
      border: 8px solid var(--border-color);
    }
  }

  .nav-items{
    width:100%;
    text-align: center;
    .active-menu{
        color: var(--primary-color);
        font-size:1.1rem;
    }
    li{
      display: block;
      a{
        font-size:1rem;
        text-transform: uppercase;
        transition: all .4s ease-in-out;
        font-weight: 600;
        letter-spacing: 1px;
        padding: 0.45rem 0;
        display: block;
        &:hover{
            cursor: pointer;
            color:var(--primary-color);
        }
      }
    }
  }

  footer {
    /*border-top:1px solid var(--border-color);*/
    width: 100%;
    p {
      padding: .3rem 0;
      font-size: 0.9rem;
      text-align:center;
    }
  }
`

export default Navbar
